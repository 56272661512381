/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

@media (min-width: 576px) {
  .col-detail-1 {
    flex: 0 0 15%;
    max-width: 15%;
    margin-left: 1rem;
    text-align: right;
    margin-right: 1rem;
  }

  .col-detail-3 {
    flex: 0 0 25%;
    max-width: 25%;
    margin-right: 1rem;
  }

  .col-detail-0 {
    flex: 0 0 10%;
    max-width: 10%;
    text-align: right;
    margin-right: 1rem;
  }
}

.applicationTable {
  overflow-y: scroll;
  height: 300px;
  display: block;
  outline: 1px solid;
  margin-bottom: 2em;
}

.attachmentTable {
  overflow-y: scroll;
  height: 440px;
  display: block;
  outline: 1px solid;
  margin-bottom: 3px;
}

.modalFooter {
  justify-content: space-between !important;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.btn-right {
  float: right;
}
.textarea-input {
  width: 100%;
  height: 100px;
}
.pop-width {
  padding: 0.5rem 0.5rem;
}
.form-select-control {
  display: block;
  width: 100%;
  /* height: calc(1em + 0.75rem + 2px); */
  padding: 0.15rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.checkbox-form-input {
  position: inherit;
  margin-top: 0.8rem;
  margin-left: 1.25rem;
}
.checkbox-label, .radio-label {
  position: inherit;
  margin-top: 0.5rem;
  margin-right: 1.25rem;
}
.radio-form-input {
  position: inherit;
  margin-top: 0.5rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}


